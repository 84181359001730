import _ from 'lodash'
var geodist = require('geodist')

export default class Scorer {
  constructor(rules) {
    this.rules = rules
    this.rulesCount = rules.map(r => 0)
  }

  distance(placement, student) {
    if (placement.latlng && student.latlng) {
      var distance = geodist(placement.latlng, student.latlng, { unit: "km" })
      return distance
    }
    return null
  }

  score(placement, student, distance) {
    var score = 0

    // Check rules
    for (var i = 0; i < this.rules.length; i++) {
      var rule = this.rules[i]

      if (typeof(rule.Placement) === "number") {
        // Check range
        if (distance >= rule.Placement && distance < rule.Student) {
          score += rule.Score
          this.rulesCount[i]++
        }
      }
      else {
        // Parse placement part
        if (rule.Placement) {
          var placementParts = rule.Placement.match(/^(.+?): *(.+)$/)
          var placementColumn = placementParts[1].trim()
          var placementValues = placementParts[2].split(/, */)
          // Check match
          if (!_.includes(placementValues, (placement[placementColumn] || "").trim())) {
            continue
          }
        }

        if (rule.Student) {
          var studentParts = rule.Student.match(/^(.+?): *(.+)$/)
          var studentColumn = studentParts[1].trim()
          var studentValues = studentParts[2].split(/, */)
          if (!_.includes(studentValues, (student[studentColumn] || "").trim())) {
            continue
          }
        }

        score += rule.Score
        this.rulesCount[i]++
      }
    }

    return score
  }
}