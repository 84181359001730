import React, { Component } from 'react';
import './App.css';
import Matcher from './Matcher'
import _ from 'lodash'

var L = require('leaflet')
require("leaflet/dist/leaflet.css")
var saveAs = require("file-saver")
var XLSX = require('xlsx')

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warnings: null,
      status: "begin", // "working", "finish"
      progress: null // Progress message
    }
  }

  renderInput() {
    let handleChange = (e) => {
      var f = e.target.files[0]
      var reader = new FileReader()
      this.setState({ status: "working" })

      reader.onload = async (e) => {
        var data = e.target.result
        var workbook = XLSX.read(data, {type: 'binary'})
        var progressCallback = (progress) => {
          this.setState({ progress })
        }
        var output
        try {
          output = await new Matcher(progressCallback).go(workbook)
        } catch (error) {
          this.setState({ status: "begin", progress: null })
          alert("Error: " + error.message)
          return
        }
        var wbout = XLSX.write(output.workbook, { bookType:'xlsx', type:'array' })
        saveAs(new Blob([wbout], {type:"application/octet-stream"}), "Placement Results.xlsx")
        this.setState({ warnings: output.warnings, status: "finish" })

        // Add to map
        for (let match of output.matches) {
          if (match[0] < 0 || match[1] < 0) {
            continue
          }
          var placement = output.placements[match[0]]
          var student = output.students[match[1]]
          if (student.latlng && placement.latlng) {
            var r = Math.floor(Math.random() * 200);
            var g = Math.floor(Math.random() * 200);
            var b = Math.floor(Math.random() * 200);
            var color= "rgb("+r+" ,"+g+","+ b+")";             
            L.polyline([student.latlng, placement.latlng], { color: color, weight: 2 })
              .bindTooltip(_.filter(_.values(student), v => typeof(v) === "string").join(" "))
              .addTo(this.map)
          }
        }
      }
      reader.readAsBinaryString(f)
    }

    var inputRef = (el) => {
      this.inputEl = el
    }

    var handleClick = () => {
      this.inputEl.click()
    }

    return (
      <div>
        <input type="file" onChange={handleChange} ref={inputRef} style={{ display: "none" }}/>
        <button type="button" className="btn btn-large btn-primary" onClick={handleClick}>
          Upload XLSX Spreadsheet...
        </button>
      </div>
    )
  }

  setupMap = (mapEl) => {
    var baseLayer = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://cartodb.com/attributions">CartoDB</a>'
    })

    var map = L.map(mapEl).setView([45.53, -73.5], 10)
    map.addLayer(baseLayer)
    this.map = map
  }

  render() {
    return (
      <div style={{padding: 10}}>
        <h2 style={{textAlign: "center"}}>ISON's Mystical Magical Placement Matcher</h2>
        <div className="well well-sm">
          <i>Note:</i> All data is processed locally in the browser completely privately and no data is uploaded to the server. 
          Postal code lookup will automatically take place if needed but as anonymous requests and not connected to student or placement information.
          <span className="text-muted">Version 0.4.0</span>
        </div>
        {
          this.state.status === "begin"
          ? 
            <div>
              {this.renderInput()}
            </div>
          : null
        }
        {
          this.state.status === "working"
          ? 
            <div>
              Working.... (this may take several minutes): <b>{this.state.progress}</b>
              <div className="progress">
                <div className="progress-bar progress-bar-striped active" role="progressbar" style={{width: "100%"}}/>
              </div>              
            </div>
          : null
        }
        {
          this.state.status === "finish"
          ? 
            <div className={ this.state.warnings.length ? "alert alert-warning" : "alert alert-success" }>
              Complete. {this.state.warnings.length ? this.state.warnings.length : "No"} warnings. File was downloaded.
              {
                this.state.warnings.map(w => <div style={{ color: "red"}}>{w}</div>)
              }
            </div>
          : null
        }
        <div>
          <h3>Map of Student-Placement Distances</h3>
          <div ref={this.setupMap} style={{height: 400 }}/>
        </div>
      </div>
    )
  }
}

export default App;


// style={{ visibility: this.state.status === "finish" ? "visible": "hidden" }}