import _ from 'lodash'
import Scorer from './Scorer'
var XLSX = require('xlsx')
var munkres = require('munkres-js')

export default class Matcher {
  constructor(progressCallback) {
    this.progressCallback = progressCallback
    this.warnings = []
  }

  async getLatlng(address) {
    if (!address) {
      return null
    }

    // Extract postal code
    var postalCodeMatch = address.match(/^[A-Za-z][0-9][A-Za-z]( ?[0-9][A-Za-z][0-9])?/)
    if (!postalCodeMatch) {
      this.warnings.push("Invalid postal code " + address)
      return null
    }
    var postalCode = address.match(/^[A-Za-z][0-9][A-Za-z]( ?[0-9][A-Za-z][0-9])?/)[0].toUpperCase()

    // Check in local storage
    var value = window.localStorage.getItem("address:" + address)
    if (value) {
      return JSON.parse(value)
    }

    this.progressCallback("Looking up postal code " + postalCode)
    var url
    if (postalCode.length > 3) {
      url = "https://maps.googleapis.com/maps/api/geocode/json?address=postal_code:" + postalCode + "&key=AIzaSyC8m68KZrA5M01awUEBQ5pW847-LKOnqWA"
    }
    else {
      url = "https://maps.googleapis.com/maps/api/geocode/json?address=postal_code_prefix:" + postalCode + "&key=AIzaSyC8m68KZrA5M01awUEBQ5pW847-LKOnqWA"
    }
    var apiLookup = await fetch(url).then(response => response.json())
    if (apiLookup.results && apiLookup.results[0] && apiLookup.results[0].geometry) {
      var location = apiLookup.results[0].geometry.location
      console.log(location.lat)
      window.localStorage.setItem("address:" + address, JSON.stringify(location))
      return location
    }
    this.progressCallback("FAILED to look up:" + postalCode)
    return null
  }

  async go(workbook, progressCallback) {
    var placements = XLSX.utils.sheet_to_json(workbook.Sheets["Placements"], { raw: false })
    var students = XLSX.utils.sheet_to_json(workbook.Sheets["Students"], { raw: false })
    var rules = XLSX.utils.sheet_to_json(workbook.Sheets["Rules"], { raw: true }) 

    // Trim all values and keys
    placements = _.map(placements, (placement) => _.mapValues(_.mapKeys(placement, (value, key) => key.trim()), (value, key) => value.trim()))
    students = _.map(students, (student) => _.mapValues(_.mapKeys(student, (value, key) => key.trim()), (value, key) => value.trim()))

    if (placements.length === 0) {
      throw new Error("No placements found. Please check that there is a tab called 'Placements'")
    }
    if (students.length === 0) {
      throw new Error("No students found. Please check that there is a tab called 'Students'")
    }
    if (rules.length === 0) {
      throw new Error("No rules found. Please check that there is a tab called 'Rules'")
    }

    for (let i = 0; i < rules.length ; i++) {
      if (_.isNaN(rules[i].Score) || !_.isNumber(rules[i].Score)) {
        throw new Error(`Invalid score for rule in row ${i + 2}`)
      }
    }

    var placementsHeaders = XLSX.utils.sheet_to_json(workbook.Sheets["Placements"], { raw: false, header: 1 })[0]
    var studentsHeaders = XLSX.utils.sheet_to_json(workbook.Sheets["Students"], { raw: false, header: 1 })[0]

    // Create "latlng" field from "Postal Code" field
    for (var student of students) {
      student["latlng"] = await this.getLatlng(student["Postal Code"])
    }
    for (var placement of placements) {
      placement["latlng"] = await this.getLatlng(placement["Postal Code"])
    }

    // Create array of placements/students with costs
    var costs = new Array(placements.length)
    for (let i = 0; i < placements.length; i++) {
      costs[i] = new Array(students.length);
    }

    var scorer = new Scorer(rules)
    var warnings = []

    var maxDistance = 0

    for (var pi = 0; pi < placements.length; pi++) {
      for (var si = 0; si < students.length; si++) {
        var distance = scorer.distance(placements[pi], students[si])

        if (distance > maxDistance) {
          maxDistance = distance
        }

        // Scores are negative
        const score = -scorer.score(placements[pi], students[si], distance)
        costs[pi][si] = score
      }
    }

    for (let i = 0; i < rules.length; i++) {
      if (scorer.rulesCount[i] === 0) {
        warnings.push("Didn't use rule " + rules[i].Placement + ":" + rules[i].Student)
      }
    }

    if (maxDistance > 100) {
      warnings.push("At least one distance was greater than 100km")
    }
    
    // Find matches
    var matches = munkres(costs)

    // Create new workbook
    var resultsWb = XLSX.utils.book_new()

    // Create column names
    var resultsColumns = ["Score", "Distance"].concat(placementsHeaders.map(h => "Placement:" + h).concat(studentsHeaders.map(h => "Student:" + h)))

    // Add non-matches
    var placementMatched = []
    var studentMatched = []
    for (let match of matches) {
      placementMatched[match[0]] = true
      studentMatched[match[1]] = true
    }
    for (let i = 0; i<placements.length ; i++) {
      if (!placementMatched[i]) {
        matches.push([i, -1])
      }
    }
    for (let i = 0; i<students.length ; i++) {
      if (!studentMatched[i]) {
        matches.push([-1, i])
      }
    }

    // Create result pairs
    var pairs = matches.map(r => {
      var placement = r[0] >= 0 ? placements[r[0]] : null
      var student = r[1] >= 0 ? students[r[1]] : null

      var row = {}

      if (placement && student) {
        var distance = scorer.distance(placement, student)
        row.Score = scorer.score(placement, student, distance)
        row.Distance = distance
      }
      if (placement) {
        for (let h of placementsHeaders) {
          row["Placement:" + h] = placement[h]
        }
      }
      if (student) {
        for (let h of studentsHeaders) {
          row["Student:" + h] = student[h]
        }
      }
      return row
    })

    pairs.sort((r1, r2) => r1.Score - r2.Score)

    var ws1 = XLSX.utils.json_to_sheet(pairs, { header: resultsColumns })
    XLSX.utils.book_append_sheet(resultsWb, ws1, "Matches")

    return { workbook: resultsWb, warnings: warnings, students, placements, matches }
  }
}
